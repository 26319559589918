/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Box,
  Flex,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
  IconButton
} from '@chakra-ui/react'
import DrillDownTable, {
  TH,
  TR,
  TD,
} from '../../../../components/dashboard-table'
import ReactExport from 'react-data-export'
import { useLoadAssetChannels, useLoadAssetReportees,useLoadLeaderBoards, useLoadSIPPayoutWidgets } from '../../../../hooks/dashboard.hooks'
import { BiDownload,BiArrowBack } from 'react-icons/bi'
import { calculateStartEndDate } from '../../../../utils/dashboard.utils'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { useAuthContext } from '../../../../context/auth.context'
import { dashboardConst } from '../../../../utils/action.constant'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../repository/repo.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function WidgetLeaderBoardView() {
  const [download, setDownload] = useState(false)
  const {
    state: { queryFilters, sipReporteeList, activeFilterOption },
    dispatch: dashboardDispatch,
  } = useDashboardContext()

  const {
    state: { authData },
  } = useAuthContext()
  const [isAccountManager, setIsAccountManager] = useState(false)
  const [showLeaderBoardData, setIsLeaderBoardData] = useState(true)

  const [isSelf, setIsSelf] = useState(true)

  const {
    mutate: mutateLoadLeaderBoards,
    data: leaderBoardData,
    isLoading: isleaderBoardDataLoading,
    isSuccess: isleaderBoardDataSuccess, 
  } = useLoadLeaderBoards()

  const {
    mutate: mutateLoadChannel,
    data: channelList,
    isLoading: isChannelLoading,
  } = useLoadAssetChannels()

  useEffect(() => {
    let reportee = { ...queryFilters.selectedCreator }
    if (reportee?.label === 'All') {
      reportee = { ...authData }
    }
    loadLeaderBoards(reportee)
  },  [
  queryFilters.selectedPeriod,
  queryFilters.selectedPeriodType,
  queryFilters.selectedSipSegment,
  queryFilters.selectedYear,
  queryFilters.selectedCluster,
  queryFilters.selectedRole,
  queryFilters.selectedVertical])

    useEffect(() => {
      return () => {
        dashboardDispatch({
          type: dashboardConst.REMOVE_SIP_RESOURCE,
        })
      }
    }, [])

    const loadAccountForAsset = manager => {
      const {
        selectedPeriodType,
        selectedPeriod,
        selectedSipSegment,
        selectedYear,
      } = queryFilters
      let { startDate, endDate } = calculateStartEndDate({
        selectedPeriod,
        selectedPeriodType,
        selectedYear,
      })
      mutateLoadChannel(
        {
          segment: selectedSipSegment,
          manager,
          startDate,
          endDate,
        },
        {
          onSuccess: data => {
            if (data.length) {
           //   loadSIPWidgetForAsset(manager)
            }
          },
        }
      )
    }
  
    
    const {
      mutate: mutateLoadSIPWidget,
      data: sipWidgetList,
      isLoading: isSipWidgetDataLoading,
      isSuccess: isSipWidgetDataSuccess,
    } = useLoadSIPPayoutWidgets()

    const {
      mutate: mutateLoadReportees,
      data: reporteeList,
      isReporteeLoading,
    } = useLoadAssetReportees()

  const loadLeaderBoards = (reportee, isManager) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters
    // let {
    //   startDate ,
    //   endDate,
    // } = calculateStartEndDate({
    //   selectedPeriod,
    //   selectedPeriodType,
    //   selectedYear,
    // })
    let clusterValue = 0
    if(selectedSipSegment?.value !== 358){
      clusterValue = !!selectedCluster ? selectedCluster.value : 0
    }
    
    mutateLoadLeaderBoards(
      {
        flag: 2,
        segment: !!selectedSipSegment ? selectedSipSegment.value : 0,
        year: !!selectedYear ? selectedYear.value : '',
        timeline: !!selectedPeriod ? selectedPeriod.value : 1,
        vertical: !!selectedVertical ? selectedVertical.value : 0,
        cluster: selectedSipSegment?.value === 0 ? 0 : clusterValue,
        role: !!selectedRole ? selectedRole.value : 0,
        creator: reportee
      },
      {
        onSuccess: data => {
          console.log(data)
          addLeaderBoards(data[1])
        },
      }
    )
  }

  const addLeaderBoards = leaderBoards => {
    dashboardDispatch({
      type: dashboardConst.ADD_LEADERBOARDS,
      leaderBoards,
    })
  }

  const loadSIPWidgetForAsset = (reportee, isAccount = false) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
    } = queryFilters
    let { startDate, endDate, startYear } = calculateStartEndDate({
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
    })

    mutateLoadSIPWidget({
      creator: reportee,
      segment: selectedSipSegment,
      timeline: selectedPeriodType,
      period: selectedPeriod,
      year: selectedYear,
      startDate,
      endDate,
    })
  }

  const addReporteeToStack = (reportee, isManager) => {
    dashboardDispatch({
      type: isManager
        ? dashboardConst.ADD_SIP_RESOURCE
        : dashboardConst.SET_SIP_RESOURCE,
      reportee: reportee,
    })
  }

  const renderReporteeHeadings = () => {
    let heads = [
      'Employee Name',
      'Employee Code',
      'Employee Email',
      'Role',
      'Segment',
      'Vertical/Cluster',
      `Weighted SIP Target vs Acheivement % (${ !!queryFilters && queryFilters.selectedPeriodType.label})`,
      'Final Payout %'
      //'Circle',
      
      
    ]

    heads = heads.map(item => (
      <TH rowSpan={2} key={item}>
        {item}
      </TH>
    ))

    let tableHead = (
      <>
        <TR>
          <TH rowSpan={1}>#</TH>
          {heads}
        </TR>
      </>
    )
    return tableHead
  }


  const renderHeadings = () => {
    let heads = [
      'Employee Name',
      'Employee Code',
      'Employee Email',
      'Role',
      'Segment',
      'Vertical/Cluster',
      `Weighted SIP Target vs Acheivement % (${ !!queryFilters && queryFilters.selectedPeriodType.label})`,
      'Final Payout %'
    ]

    heads = heads.map(item => (
      <TH rowSpan={2} key={item}>
        {item}
      </TH>
    ))

    let tableHead = (
      <>
        <TR>
          <TH rowSpan={1}>#</TH>
          {heads}
        </TR>
      </>
    )
    return tableHead
  }

  const loadResourceForAsset = (reportee, isManager = false) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
    } = queryFilters
    mutateLoadReportees(
      { flag: 10,
        creator: reportee,
        segment: selectedSipSegment?.value,
        timeline_id: selectedPeriodType?.value,
        periodTypeId: selectedPeriod?.value || 0,
        year: selectedYear?.value.replaceAll(' ','') || '' },
      {
        onSuccess: data => {
          addReporteeToStack(reportee, isManager)
          if (data.length) {
          //  loadSIPWidgetForAsset(reportee)
          }
        },
      }
    )
  }

  const makeTableData = () => {
    let data = []
    let _arr = !!leaderBoardData && leaderBoardData.length > 0 ? leaderBoardData  : []
    _arr?.forEach((item, i) => {
      let obj = {}
      obj['s.no'] = i + 1
      obj['Employee Name'] = item.operating_asset_first_name
      obj['Employee Code'] = item.asset_customer_unique_id
      obj['Employee Email'] = item.operating_asset_email_id
      obj['Role'] = item.employee_role
      obj['Segment'] = item.employee_segment
      obj['Vertical/Cluster'] = item.vertical_tag_name
     // obj['Circle'] = item.employee_circle
      obj[`Weighted SIP Target vs Acheivement % (${ !!queryFilters && queryFilters.selectedPeriodType.label})`] = 
      
      queryFilters?.selectedPeriodType?.label === 'YTD' ? 
      (!!item.weighted_sip_target_ach_ytd ? item.weighted_sip_target_ach_ytd.toFixed(2) + '%' : '0.00%')
      : queryFilters?.selectedPeriodType?.label === 'QTD' ?
      (!!item.weighted_sip_target_ach_qtd ? item.weighted_sip_target_ach_qtd.toFixed(2) + '%' : '0.00%')
      : queryFilters?.selectedPeriodType?.label === 'MTD' ? 
      (!!item.weighted_sip_target_ach_mtd ? item.weighted_sip_target_ach_mtd.toFixed(2) + '%' : '0.00%')
      : (!!item.weighted_sip_target_ach_ytd ? item.weighted_sip_target_ach_ytd.toFixed(2) + '%' : '0.00%')



      obj['Final Payout Percentage %'] = !!item.final_payout_percentage ? item.final_payout_percentage.toFixed(2) + '%' : '0.00%'

      data.push(obj)
    })
    return [data, _arr]
  }

  const renderData = () => {
    let [data, _arr] = makeTableData()
    return data.map((item, i) => (
      <TR key={i}>
        {Object.values(item).map((val, j) =>
          j === 1 ? (
            <TD
              key={j}
              onClickHandler={() => {
                _arr[i].label=_arr[i].operating_asset_first_name
                dashboardDispatch({
                type:dashboardConst.ADD_ACTIVE_FILTER_OPTION,
                activeFilterOption:_arr[i]
              })
                loadResourceForAsset(_arr[i])
                setIsLeaderBoardData(false)
              }}
              classes={_arr[i].asset_flag_frontline !== 1 ? `text-bold green-theme cursor-pointer` :  `text-bold green-theme`}
            >
              {val}
            </TD>
          ) : (
            <TD key={j}>{val}</TD>
          )
        )}
      </TR>
    ))
  }

  const renderReporteeData = () => {
    let [data, _arr] = makeReporteeTableData()
    return data.map((item, i) => (
      <TR key={i}>
        {Object.values(item).map((val, j) =>
          j === 1 ? (
            <TD
              key={j}
              onClickHandler={() => {
                _arr[i].label=_arr[i].operating_asset_first_name
                dashboardDispatch({
                type:dashboardConst.ADD_ACTIVE_FILTER_OPTION,
                activeFilterOption:_arr[i]
              })
                loadResourceForAsset(_arr[i])}}
              classes={`text-bold green-theme cursor-pointer`}
            >
              {val}
            </TD>
          ) : (
            <TD key={j}>{val}</TD>
          )
        )}
      </TR>
    ))
  }

  const makeReporteeTableData = () => {
    let data = []
    let _arr = reporteeList
    _arr?.forEach((item, i) => {
      let obj = {}
      obj['s.no'] = i + 1
      obj['Employee Name'] = item.operating_asset_first_name
      obj['Employee Code'] = item.asset_customer_unique_id
      obj['Employee Email'] = item.operating_asset_email_id
      obj['Role'] = item.asset_type_name
      obj['Segment'] = item.workforce_tag_name
      obj['Vertical/Cluster'] = item.asset_tag_name_1
      obj[`Weighted SIP Target vs Acheivement % (${ !!queryFilters && queryFilters.selectedPeriodType.label})`] = !!item.weighted_sip_target_ach ? item.weighted_sip_target_ach.toFixed(2) + '%' : '0.00%'
      obj['Final Payout Percentage %'] = !!item.final_payout_percentage ? item.final_payout_percentage.toFixed(2) + '%' : '0.00%'

      data.push(obj)
    })
    return [data, _arr]
  }

  const handleDownload = () => {
    setDownload(true)
  }

  const exportHeadings = () => {
    let heads = [
      'Employee Name',
      'Employee Code',
      'Employee Email',
      'Role',
      'Segment',
      'Vertical/Cluster',
      `Weighted SIP Target vs Acheivement % (${ !!queryFilters && queryFilters.selectedPeriodType.label})`,
      'Final Payout Percentage %'
    ]
    return heads
  }

  const renderExportData = () => {
    let dataSet = makeTableData()
    let reporteeData = makeReporteeTableData()
    console.log(dataSet[0],reporteeData[0])

    const fileName = 'SIP_Download'
    return (
      <ExcelFile filename={fileName} hideElement={true}>
        {[{ sheet: 'sheet1', data: dataSet[0], heads: exportHeadings() },
        { sheet: 'sheet2', data: reporteeData[0], heads: exportHeadings() }].map(
          item => (
            <ExcelSheet data={item.data} name={item.sheet}>
              {item.heads?.map(headerItem => (
                <ExcelColumn label={headerItem} value={headerItem} />
              ))}
            </ExcelSheet>
          )
        )}
      </ExcelFile>
    )
  }

  const widgetMainView = isleaderBoardDataLoading ? (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  ) : (
    <Box>
      <Flex mb={3} p={3} alignItems="center">
        <Icon
          as={BiDownload}
          w={6}
          h={6}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_DASHBOARD,
              action: 'Widget LeaderBoard View',
              label: 'Download',
            })
            handleDownload()
          }}
          className={`cursor-pointer`}
        />

        {!!sipReporteeList &&
            sipReporteeList.length > 0 &&  (<>
        <IconButton
              bg="white"
              aria-label="Refresh"
              cursor="pointer"
              size="md"
              _focus={{
                bg: 'secondary',
              }}
              _hover={{
                bg: 'secondary',
              }}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DASHBOARD,
                  action: 'Widget LeaderBoard View',
                  label: 'Load Resource For Asset',
                })
                dashboardDispatch({
                  type: dashboardConst.REMOVE_SIP_RESOURCE,
                })
                loadLeaderBoards()
                setIsLeaderBoardData(true)
              }}
              icon={<Icon as={BiArrowBack} w={6} h={6} />}
            />
            </>)
}
        &nbsp;&nbsp;
        {download && renderExportData()}

        {!isAccountManager &&
            !!sipReporteeList && 
            sipReporteeList.map((item, i) => (
              <>
                {i !== 0 ? '/' : null}
                
                <Text
                  fontWeight="bold"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_DASHBOARD,
                      action: 'Widget LeaderBoard View',
                      label: 'Load Resource For Asset',
                    })
                    dashboardDispatch({
                      type:dashboardConst.ADD_ACTIVE_FILTER_OPTION,
                      activeFilterOption:item
                    })
                    loadResourceForAsset(item)
                  }}
                  color={localStorage.getItem('color')}
                  cursor="pointer"
                  textDecoration="underline"
                >
                  {' '}
                  {item.operating_asset_first_name}{' '}
                </Text>
              </>
            ))}
      </Flex>
        { isleaderBoardDataSuccess ? (
          <VStack spacing={8}>
           <>
           {!!leaderBoardData && showLeaderBoardData ? (
        <DrillDownTable theadings={renderHeadings()} tdata={renderData()} />
        ) : null}
        </>
        {!!reporteeList && !showLeaderBoardData ? (<DrillDownTable theadings={renderReporteeHeadings()} tdata={renderReporteeData()} />) : null}
      </VStack>
        ) : null}
    </Box>
  )

  return (
    <Box bg="white" rounded="md" p="8px">
      {widgetMainView}
    </Box>
  )
}

export default WidgetLeaderBoardView
