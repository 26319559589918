/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Box, Flex, Icon, Skeleton, Stack, VStack } from '@chakra-ui/react'
import DrillDownTable, {
  TH,
  TR,
  TD,
} from '../../../../components/dashboard-table'
import ReactExport from 'react-data-export'
import { BiDownload } from 'react-icons/bi'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { useAuthContext } from '../../../../context/auth.context'
import { useLoadPayoutAssetReportees, useLoadPenUtilReport } from '../../../../hooks/dashboard.hooks'
import { calculateStartEndDate } from '../../../../utils/dashboard.utils'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../repository/repo.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function WidgetPenUtlView() {
  const {
    state: { queryFilters },
  } = useDashboardContext()
  const {
    state: { authData },
  } = useAuthContext()
  const [download, setDownload] = useState(false)

  // const {
  //   mutate: mutateLoadReportees,
  //   data: reporteeList,
  //   isLoading: isLoading,
  // } = useLoadPayoutAssetReportees()

  const {
    mutate: mutateLoadPenUtilReport,
    data: penUtilreport,
    isLoading: ispenUtilLoading,
    isSuccess: ispenutilSuccess
  } = useLoadPenUtilReport()

  useEffect(() => {
    let reportee = { ...queryFilters.selectedCreator }
    if (reportee?.label === 'All') {
      reportee = { ...authData }
      queryFilters.selectedCreator = {label: authData.operating_asset_first_name, asset_id :authData.asset_id}
    } 
    loadResourceForAsset(reportee)
  }, [
    queryFilters.selectedCreator,
    queryFilters.selectedPeriod,
    queryFilters.selectedPeriodType,
    queryFilters.selectedSipSegment,
    queryFilters.selectedYear])

  const loadResourceForAsset = (reportee, isManager = false) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
    } = queryFilters
    // let {
    //   startDate,
    //   endDate,
    // } = calculateStartEndDate({
    //   selectedPeriod,
    //   selectedPeriodType,
    //   selectedYear,
    // })
    // mutateLoadReportees({
    //   flag: 6,
    //   manager: reportee?.asset_id,
    //   segment: !!selectedSipSegment ? selectedSipSegment.value : 0,
      
    //   // startDate,
    //   // endDate,
    // })

    mutateLoadPenUtilReport({
      segment: !!selectedSipSegment ? selectedSipSegment.value : 0,
      periodType: !!selectedPeriod ? selectedPeriod.value : 1,
      year: !!selectedYear ? selectedYear.value : '',
      reportee: reportee
    })
  }

  const renderHeadings = () => {
    let heads = [
      'Segment',
      queryFilters?.selectedPeriodType?.label === 'YTD' || queryFilters?.selectedPeriodType?.label === 'HTD' ? 'Employees on SIP(.Avg)' : 'Employees on SIP',
      queryFilters?.selectedPeriodType?.label === 'YTD' || queryFilters?.selectedPeriodType?.label === 'HTD' ? 'Qualified for SIP(.Avg)' : 'Qualified for SIP',
      queryFilters?.selectedPeriodType?.label === 'YTD' || queryFilters?.selectedPeriodType?.label === 'HTD' ? 'SIP Penetration %(.Avg)' : 'SIP Penetration %',
      queryFilters?.selectedPeriodType?.label === 'YTD' || queryFilters?.selectedPeriodType?.label === 'HTD' ? 'SIP Utilization %(.Avg)' : 'SIP Utilization %',
    ]

    heads = heads.map(item => (
      <TH rowSpan={2} key={item}>
        {item}
      </TH>
    ))

    let tableHead = (
      <>
        <TR>
          <TH rowSpan={1}>#</TH>
          {heads}
        </TR>
      </>
    )
    return tableHead
  }

  const makeTableData = () => {
    let data = []
    !!penUtilreport &&
      Array.isArray(penUtilreport) &&
      penUtilreport.map((item, i) => {
        let obj = {}
        obj['S no'] = i+1
        obj['Segment'] = item?.employee_segment
        obj['Employees on SIP'] = item?.employees_on_sip
        obj['Qualified for SIP'] = item?.qualified_for_sip
        obj['SIP Penetration %'] = !!item?.percentage_penetration ? item?.percentage_penetration.toFixed(2) + '%' : '-'
        obj['SIP Utilization %'] = !!item?.percentage_utilization ? item?.percentage_utilization.toFixed(2) + '%' : '-'
        if(!!item.employee_segment && item.employee_segment !== null)
        data.push(obj)
      })
    return data
  }

  const renderData = () => {
    let data = makeTableData()
    return data.map((item, i) => (
      <TR key={i}>
        {!!Object.values(item) && Object.values(item).map((val, j) => (
          <TD key={j}>{val}</TD>
        ))}
      </TR>
    ))
  }

  const handleDownload = () => {
    setDownload(true)
  }

  const exportHeadings = () => {
    let heads = [
      'Segment',
      'Employees on SIP',
      'Qualified for SIP',
      'SIP Penetration %',
      'SIP Utilization %',
    ]
    return heads
  }

  const renderExportData = () => {
    let dataSet = makeTableData()

    const fileName = 'SIP Pen/Util Report'
    return (
      <ExcelFile filename={fileName} hideElement={true}>
        {[{ sheet: 'SIP Data', data: dataSet, heads: exportHeadings() }].map(
          item => (
            <ExcelSheet data={item.data} name={item.sheet}>
              {item.heads?.map(headerItem => (
                <ExcelColumn label={headerItem} value={headerItem} />
              ))}
            </ExcelSheet>
          )
        )}
      </ExcelFile>
    )
  }

  const widgetMainView = ispenUtilLoading ? (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  ) : (
    <Box>
      <Flex mb={3} p={3} alignItems="center">
        <Icon
          as={BiDownload}
          w={6}
          h={6}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_DASHBOARD,
              action: 'Widget Pen Util View',
              label: 'Download',
            })
            handleDownload()
          }}
          className={`cursor-pointer`}
        />
        &nbsp;&nbsp;
        {download && renderExportData()}
      </Flex>
      <VStack spacing={8}>
        {ispenutilSuccess ? (<>
          <DrillDownTable theadings={renderHeadings()} tdata={renderData()} />
        </>) : []}
       
      </VStack>
    </Box>
  )

  return (
    <Box bg="white" rounded="md" p="8px">
      {widgetMainView}
    </Box>
  )
}

export default WidgetPenUtlView
